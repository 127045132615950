import React from "react";
import { FormProps } from "../../../types/FormProps";

const IntroFrench: React.FC<FormProps> = ({ currentForm, setCurrentForm }) => {
    return (
        <div
            id="typeform-intro"
            className="typeform-container typeform-introscreen"
        >
            <div className="typeform-introscreen__inner padding--top align--left bigger-paragraph">
                <p className={"left"}>
                    Cher participant, le projet #relation2test consiste à mettre
                    votre propre relation à l'épreuve et à découvrir si vous
                    êtes dans une relation d'égalitaire ou s'il existe des
                    signes de violence émotionnelle ou même physique dans votre
                    relation. La version suivante est une version test du
                    questionnaire, dans laquelle nous demandons votre
                    coopération.
                </p>
                <p className={"left"}>
                    Ce n'est que grâce à vos commentaires que nous pouvons
                    améliorer la qualité du questionnaire et ainsi aider à
                    l'avenir à prendre au sérieux les signaux d'avertissement
                    pertinents avant même le recours à la force et à prévenir
                    les conséquences éventuellement désastreuses d'une relation
                    violente. Comme suggestion de ce qu'il faut rechercher dans
                    cette phase importante du développement des tests, nous
                    avons énuméré quelques exemples:
                </p>
                <ul className={"align--left"}>
                    <li>Sind die Fragen verständlich formuliert?</li>
                    <li>Ist die Sprache passend gewählt?</li>
                    <li>
                        Sind die Auswahlmöglichkeiten der Antworten ausreichend?
                    </li>
                    <li>
                        Ist die Reihenfolge der Fragen aus Ihrer Sicht
                        schlüssig?
                    </li>
                    <li>Ist der Fragebogen zu lang/zu kurz?</li>
                    <li>Fehlt Ihnen etwas im Fragebogen?</li>
                </ul>
                <p className={"left"}>
                    Am Ende erhalten sie eine Auswertung. Diese soll Sie, je
                    nachdem in welcher Kategorie Ihre Auswertung ist, noch über
                    Ihr Ergebnis informieren und zum Thema sensibilisieren. Wir
                    freuen uns hier über folgende Rückmeldung:
                </p>
                <ul className={"align--left"}>
                    <li>
                        Fühlen Sie sich durch die Auswertung ausreichend
                        informiert und sensibilisiert?
                    </li>
                    <li>Ist Ihnen sonst noch etwas aufgefallen?</li>
                    <li>Ist auch hier die Sprache verständlich?</li>
                    <li>
                        Wie fanden Sie die Bedienerfreundlichkeit des Tests?
                    </li>
                </ul>
                <button
                    className="js-start button button-link primary primary--hover font--big color--secondary"
                    onClick={() => setCurrentForm("gender")}
                >
                    Continuer
                </button>
            </div>
        </div>
    );
};
export default IntroFrench;
