import React from "react";
import { FormProps } from "../../../types/FormProps";

const IntroEnglish: React.FC<FormProps> = ({ currentForm, setCurrentForm }) => {
    return (
        <div
            id="typeform-intro"
            className="typeform-container typeform-introscreen"
        >
            <div className="typeform-introscreen__inner padding--top align--left bigger-paragraph">
                <p className={"left"}>
                    Dear participant, the #relation2test project is about
                    putting your own relationship to the test and finding out
                    whether you are in an equal relationship or whether there
                    are any signs of emotional or even physical violence in your
                    relationship. The following version is a test version of the
                    questionnaire, in which we ask for your cooperation.
                </p>
                <p className={"left"}>
                    Only through your feedback can we improve the quality of the
                    questionnaire and thus help in the future to take the
                    relevant warning signs seriously even before the use of
                    force and prevent the potentially dire consequences of a
                    violent relationship. As a suggestion of what to look out
                    for in this important phase of test development, we have
                    listed a few examples:
                </p>
                <ul className={"align--left"}>
                    <li>Sind die Fragen verständlich formuliert?</li>
                    <li>Ist die Sprache passend gewählt?</li>
                    <li>
                        Sind die Auswahlmöglichkeiten der Antworten ausreichend?
                    </li>
                    <li>
                        Ist die Reihenfolge der Fragen aus Ihrer Sicht
                        schlüssig?
                    </li>
                    <li>Ist der Fragebogen zu lang/zu kurz?</li>
                    <li>Fehlt Ihnen etwas im Fragebogen?</li>
                </ul>
                <p className={"left"}>
                    Am Ende erhalten sie eine Auswertung. Diese soll Sie, je
                    nachdem in welcher Kategorie Ihre Auswertung ist, noch über
                    Ihr Ergebnis informieren und zum Thema sensibilisieren. Wir
                    freuen uns hier über folgende Rückmeldung:
                </p>
                <ul className={"align--left"}>
                    <li>
                        Fühlen Sie sich durch die Auswertung ausreichend
                        informiert und sensibilisiert?
                    </li>
                    <li>Ist Ihnen sonst noch etwas aufgefallen?</li>
                    <li>Ist auch hier die Sprache verständlich?</li>
                    <li>
                        Wie fanden Sie die Bedienerfreundlichkeit des Tests?
                    </li>
                </ul>
                <button
                    className="js-start button button-link primary primary--hover font--big color--secondary"
                    onClick={() => setCurrentForm("gender")}
                >
                    Continue
                </button>
            </div>
        </div>
    );
};
export default IntroEnglish;
