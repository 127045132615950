import { Strings } from "../types";
import de from "./de";
import en from "./en";
import fr from "./fr";

const strings: Strings = {
    de: de,
    fr: fr,
    en: en,
};

export default strings;
