import React, { useState, useEffect } from "react";
import { FormProps } from "../../types/FormProps";
import Link from "next/link";
import calcEval from "../../helpers/calcEval";
import { ResponseItemData } from "../../types/ResponseDataProps";
import { useLocale } from "../../hooks/localeContext";
import Beratungsstellen from "../Items/Beratungsstellen";
import TextFinalGreen from "../Items/TextFinalGreen";
import TextFinalYellow from "../Items/TextFinalYellow";
import TextFinalOrange from "../Items/TextFinalOrange";
import TextFinalRed from "../Items/TextFinalRed";
import useTranslation from "../../hooks/useTranslation";
type Eval = "" | "green" | "yellow" | "orange" | "red" | "error";

const Results: React.FC<FormProps> = ({
    score,
    setCurrentForm,
    gender,
    submitId,
}) => {
    const [final, setFinal] = useState<ResponseItemData>();
    const [error, setError] = useState<boolean>(false);
    const { locale, t } = useTranslation();

    useEffect(() => {
        // @ts-ignore
        let finalEval: ResponseItemData = calcEval(null, score, setError);
        setFinal(finalEval);

        return () => {
            //clean up
        };
    }, [score]);

    const showEval = () => {
        const { sumBlue, sumGreen, sumRed } = score;
        //console.log("FinalEval: ", final);
        //console.log("Final: ", final);
        //console.log("Score: ", score);

        if (error) {
            return (
                <div className={"results-error"}>
                    <h2>
                        Es gab leider einen Fehler bei der Auswertung ihres
                        Tests.
                    </h2>
                    <p>
                        Falls Sie ihr Resultat dennoch gerne erfahren möchten,
                        melden Sie sich bei{" "}
                        <Link href="/kontakt">
                            <a>uns</a>
                        </Link>
                    </p>
                </div>
            );
        }

        return (
            <>
                <div className={"result__details"}>
                    <p>
                        Egalitäre Beziehung: {Math.round(sumBlue * 100) / 100}{" "}
                    </p>
                    <p>
                        Emotionale Warnsignale:{" "}
                        {Math.round(sumGreen * 100) / 100}
                    </p>
                    <p>
                        Körperliche Warnsignale:{" "}
                        {Math.round(sumRed * 100) / 100}{" "}
                    </p>
                </div>
                <div className="flex flex-direction--column fullWidth flex-center--vertical">
                    <div
                        className={`result-colored-container ${final?.finalEval}`}
                    >
                        {" "}
                    </div>
                    {final?.finalEval === "green" && <TextFinalGreen />}
                    {final?.finalEval === "yellow" && <TextFinalYellow />}
                    {final?.finalEval === "orange" && <TextFinalOrange />}
                    {final?.finalEval === "red" && <TextFinalRed />}
                </div>
            </>
        );
    };

    const downloadPdf = () => {
        switch (final?.finalEval) {
            case "green":
                window.open(
                    `/assets/pdfs/${locale}-results/${locale}-grun.pdf`,
                    "_blank",
                );
                break;
            case "yellow":
                window.open(
                    `/assets/pdfs/${locale}-results/${locale}-gelb.pdf`,
                    "_blank",
                );
                break;
            case "orange":
                window.open(
                    `/assets/pdfs/${locale}-results/${locale}-orange.pdf`,
                    "_blank",
                );
                break;
            case "red":
                window.open(
                    `/assets/pdfs/${locale}-results/${locale}-rot.pdf`,
                    "_blank",
                );
                break;
            default:
                break;
        }
    };

    const sendFeedback = () => {
        setCurrentForm("feedback");
    };

    return (
        <div
            id="typeform-results"
            className="typeform-container typeform-results"
        >
            <div className="typeform-results__inner">
                <div className="typeform-results__loading show">
                    <h1>{t("yourResult")}</h1>
                    {showEval()}
                </div>
                <div className="flex flex-space-between">
                    <button
                        className="button button-link secondary secondary--hover"
                        onClick={() => downloadPdf()}
                    >
                        {t("downloadInfos")}
                    </button>
                    {/*<button
                        className="button button-link primary primary--hover color--secondary"
                        onClick={() => sendFeedback()}
                    >
                        Feedback abgeben
                    </button>*/}
                </div>
            </div>
        </div>
    );
};
export default Results;
