import React from "react";
import { FormProps } from "../../../types/FormProps";

const IntroGerman: React.FC<FormProps> = ({ currentForm, setCurrentForm }) => {
    return (
        <div
            id="typeform-intro"
            className="typeform-container typeform-introscreen"
        >
            <div className="typeform-introscreen__inner padding--top align--left bigger-paragraph">
                <p className={"left"}>
                    Sehr geehrte*r Teilnehmer*in, in dem Projekt #relation2test
                    geht es darum, seine eigene Beziehung auf die Probe zu
                    stellen und herauszufinden, ob Sie in einer gleichwertigen
                    Beziehung sind, oder ob Anzeichen für emotionale oder gar
                    körperliche Gewalt in Ihrer Beziehung vorliegen. In der
                    folgenden Version handelt es sich um einen Fragebogen als
                    Testversion, bei der wir Sie um Ihre Mitarbeit bitten.
                </p>
                <p className={"left"}>
                    Nur durch Ihre Rückmeldung, können wir die Qualität des
                    Fragebogens verbessern und somit in Zukunft helfen, bereits
                    vor der Gewaltanwendung, die entsprechenden Warnzeichen
                    ernst zu nehmen und möglicherweise schlimme Konsequenzen
                    einer Gewaltbeziehung verhindern. Als Anregung, worauf Sie
                    in dieser wichtigen Phase der Testentwicklung achten können,
                    listen wir Ihnen einige Beispiele auf:
                </p>
                <ul className={"align--left"}>
                    <li>Sind die Fragen verständlich formuliert?</li>
                    <li>Ist die Sprache passend gewählt?</li>
                    <li>
                        Sind die Auswahlmöglichkeiten der Antworten ausreichend?
                    </li>
                    <li>
                        Ist die Reihenfolge der Fragen aus Ihrer Sicht
                        schlüssig?
                    </li>
                    <li>Ist der Fragebogen zu lang/zu kurz?</li>
                    <li>Fehlt Ihnen etwas im Fragebogen?</li>
                </ul>
                <p className={"left"}>
                    Am Ende erhalten sie eine Auswertung. Diese soll Sie, je
                    nachdem in welcher Kategorie Ihre Auswertung ist, noch über
                    Ihr Ergebnis informieren und zum Thema sensibilisieren. Wir
                    freuen uns hier über folgende Rückmeldung:
                </p>
                <ul className={"align--left"}>
                    <li>
                        Fühlen Sie sich durch die Auswertung ausreichend
                        informiert und sensibilisiert?
                    </li>
                    <li>Ist Ihnen sonst noch etwas aufgefallen?</li>
                    <li>Ist auch hier die Sprache verständlich?</li>
                    <li>
                        Wie fanden Sie die Bedienerfreundlichkeit des Tests?
                    </li>
                </ul>
                <button
                    className="js-start button button-link primary primary--hover font--big color--secondary"
                    onClick={() => setCurrentForm("gender")}
                >
                    Weiter
                </button>
            </div>
        </div>
    );
};
export default IntroGerman;
