/*<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png">
<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
<link rel="manifest" href="/site.webmanifest">*/

import React from "react";
import Head from "next/head";

const HtmlHead: React.FC = ({ children }) => {
    return (
        <Head>
            <title>Relation2Test</title>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/favicon/apple-touch-icon.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon/favicon-16x16.png"
            />
            <meta name="author" content="FMPO, neon, danielwahl.lu" />
            <link rel="manifest" href="/favicon/site.webmanifest" />
            {children}
        </Head>
    );
};

export default HtmlHead;
