import React, { useState } from "react";
import { FormProps } from "../../types/FormProps";
import { useLocale } from "../../hooks/localeContext";

const Start: React.FC<FormProps> = ({
    currentForm,
    setCurrentForm,
    language,
    setLanguage,
}) => {
    const { locale, setLocale } = useLocale();
    const [hover, setHover] = useState("");
    return (
        <div
            id="typeform-start"
            className="typeform-container typeform-startscreen"
        >
            <div className="typeform-startscreen__inner">
                <div className="typeform-startscreen__inner__text">
                    {(hover === "fr" || (locale === "fr" && hover === "")) && (
                        <>
                            <h1>Testez votre relation....</h1>
                            <h2>
                                parce que la violence ne commence pas forcement
                                par des coups.
                            </h2>
                            <p>
                                Découvrez avec ce test si votre relation est
                                égalitaire ou s’il y a des signes de violence
                                émotionnelle/psychologique ou même physique dans
                                votre relation.
                            </p>
                        </>
                    )}
                    {(hover === "de" || (locale === "de" && hover === "")) && (
                        <>
                            <h1>Mach den Beziehungstest....</h1>
                            <h2>
                                denn Gewalt fängt nicht erst mit Schlägen an.
                            </h2>
                            <p>
                                Finden Sie mit diesem Test heraus ob Ihre
                                Beziehung egalitär bzw. gleichwertig gestaltet
                                ist, oder ob Anzeichen emotionaler / psychischer
                                oder gar körperlicher Gewalt in Ihrer Beziehung
                                vorhanden sind.
                            </p>
                        </>
                    )}
                    {(hover === "en" || (locale === "en" && hover === "")) && (
                        <>
                            <h1>Do the relationship test....</h1>
                            <h2>
                                because violence doesn't just start with
                                beatings.
                            </h2>
                            <p>
                                Find out with this test if your relationship is
                                equal, or if there are signs of emotional /
                                psychological or even physical violence in your
                                relationship.
                            </p>
                        </>
                    )}
                </div>
                <div className="flex flex-center--vertical flex-center--horizontal">
                    <button
                        className="js-start button button-link primary primary--hover font--big color--secondary relative shadow--hover"
                        onClick={() => {
                            setCurrentForm("gender");
                            setLanguage("german");
                            setLocale("de");
                        }}
                        onMouseEnter={() => setHover("de")}
                    >
                        Starte den Test <span className="language">DE</span>
                    </button>
                    <button
                        className="js-start button button-link primary primary--hover font--big color--secondary relative shadow--hover"
                        onClick={() => {
                            setCurrentForm("gender");
                            setLanguage("french");
                            setLocale("fr");
                        }}
                        onMouseEnter={() => setHover("fr")}
                    >
                        Commencer le test <span className="language">FR</span>
                    </button>
                    <button
                        className="js-start button button-link primary primary--hover font--big color--secondary relative shadow--hover"
                        onClick={() => {
                            setCurrentForm("gender");
                            setLanguage("english");
                            setLocale("en");
                        }}
                        onMouseEnter={() => setHover("en")}
                    >
                        Begin the test <span className="language">EN</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Start;
