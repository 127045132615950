import React, { useEffect } from "react";
import { createWidget } from "@typeform/embed";
import { FormProps } from "../../types/FormProps";

type WD = Window | Document | HTMLIFrameElement | HTMLDocument | any;

const Female: React.FC<FormProps> = ({
    setCurrentForm,
    setScore,
    setSubmitId,
    language,
}) => {
    useEffect(() => {
        //G1M2EXph -->female
        //mqnFp55E -->test
        let formId = "G1M2EXph"; // german
        if (language === "french") formId = "fqrbY6ma";
        else if (language === "english") formId = "iQIGLspf";

        createWidget(formId, {
            container: document.getElementById("typeform-female"),
            hideFooter: false,
            hideHeaders: true,
            opacity: 0,
            onSubmit: (event) => {
                let id = event.responseId;
                //console.log("response event: ", event.responseId)
                setSubmitId(id);
                const call = async () => {
                    await fetch(`/api/eval/${formId}/${id}`, {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    })
                        .then((response) => response.json())
                        .then((res) => {
                            //console.log("Female fetch res: ", res);
                            setScore({
                                sumBlue: res[1],
                                sumGreen: res[2],
                                sumRed: res[3],
                            });
                            setCurrentForm("results");
                        })
                        .catch((error) => console.log(error));
                };

                setTimeout(() => call().then(), 1500);

                //console.log("Submit Female");
            },
        });

        /* Trying to make font of TypeForm smaller  --> not possible -> only errors
		setTimeout(() => {
			//frames[0].document.body.querySelectorAll<HTMLElement>("span, p").forEach(item => item.style.fontSize = "1rem");
			//frames[0].document.body.querySelector<HTMLElement>("[class^='screen_StickyFooter']").style.background = "transparent"
			let iframe = document.querySelector<HTMLIFrameElement>(".typeform-widget iframe")
			let iframeContent:WD = (iframe.contentWindow || iframe.contentDocument)
			if(iframeContent.document) iframeContent = iframeContent.document
			iframeContent.body.querySelectorAll("span, p").forEach(item => item.style.fontSize = "1rem");
			frames[0].document.body.querySelector<HTMLElement>("[class^='screen_StickyFooter']").style.background = "transparent"
		}, 2000);
		*/
    }, []);

    return <div id="typeform-female" className="typeform-container"></div>;
};
export default Female;
