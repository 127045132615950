import React from "react";
import { useLocale } from "../../hooks/localeContext";

const TextFinalGreen: React.FC = () => {
    const { locale } = useLocale();
    return (
        <div className={"results__information"}>
            {locale === "de" && (
                <>
                    <h2>
                        Herzlichen Glückwunsch! Ihre Beziehung ist egalitär!{" "}
                    </h2>
                    <h3>Egalitäre / gleichwertige Beziehungsführung</h3>
                    <p>
                        Ihr Test zeigt keine Anzeichen von Gewalt innerhalb
                        Ihrer Partnerschaft auf. Sie sind in einer Beziehung, in
                        der sich beide Partner*innen auf Augenhöhe mit Respekt
                        begegnen, Sie über Ihre Anliegen reden können und als
                        eine Art „Team“ in der Beziehung miteinander
                        funktionieren.
                    </p>
                    <p>
                        Bitte beachten Sie, dass dieses Testergebnis keinen
                        Aufschluss darüber gibt, wie zufrieden Sie in Ihrer
                        Beziehung sind, da dies auch ganz andere Gründe haben
                        kann.
                    </p>
                </>
            )}
            {locale === "fr" && (
                <>
                    <h2>Félicitations ! Votre relation est égalitaire !</h2>
                    <h3>Vous avez une relation égalitaire</h3>
                    <p>
                        Votre test ne montre aucun signe de violence au sein de
                        votre relation. Vous avez une relation d’égal à égal qui
                        fonctionne dans le respect. Vous pouvez parler de vos
                        préoccupations et vous fonctionnez bien ensemble.
                    </p>
                    <p>
                        Veuillez noter que ce résultat de test n’évalue pas
                        votre degré de satisfaction dans votre relation parce
                        que d’autres raisons peuvent entrer en ligne de compte.
                    </p>
                </>
            )}
            {locale === "en" && (
                <>
                    <h2>Congratulations! Your relationship is egalitarian!</h2>
                    <h3>Egalitarian/equal relationship</h3>
                    <p>
                        Your test shows no signs of violence in your
                        partnership. You are in an equal relationship in which
                        both partners treat one another as equals and with
                        respect, are able to discuss their concerns and function
                        as a sort of “team”.
                    </p>
                    <p>
                        Please note that this test result is not intended to
                        indicate how satisfied you are in your relationship, as
                        this may be based on completely different factors.
                    </p>
                </>
            )}
        </div>
    );
};

export default TextFinalGreen;
