import React, { useState, useEffect } from "react";
import Start from "../TypeForms/Start";
import Intro from "../TypeForms/Intro";
import Gender from "../TypeForms/Gender";
import Male from "../TypeForms/Male";
import Female from "../TypeForms/Female";
import Divers from "../TypeForms/Divers";
import Results from "../TypeForms/Results";
import Feedback from "../TypeForms/Feedback";
import { formType, TLanguage } from "../../types/formType";
import IntroGerman from "../TypeForms/Intro/IntroGerman";
import IntroFrench from "../TypeForms/Intro/IntroFrench";
import IntroEnglish from "../TypeForms/Intro/IntroEnglish";

interface Score {
    sumBlue: number;
    sumGreen: number;
    sumRed: number;
}
const defaultScore: Score = {
    sumBlue: 3.1,
    sumGreen: 10.8,
    sumRed: 1.5,
};

const Main: React.FC = () => {
    const [currentForm, setCurrentForm] = useState<formType>("start");
    const [language, setLanguage] = useState<TLanguage>("");

    const [score, setScore] = useState<Score>(defaultScore);
    const [gender, setGender] = useState("");
    const [submitId, setSubmitId] = useState("");

    useEffect(() => {
        if (currentForm !== "start") {
            document.querySelector("body").classList.add("lighter");
        } else {
            document.querySelector("body").classList.remove("lighter");
        }
    }, [currentForm]);

    return (
        <main>
            {currentForm === "start" && (
                <Start
                    setCurrentForm={setCurrentForm}
                    language={language}
                    setLanguage={setLanguage}
                />
            )}
            {currentForm === "intro" && language === "german" && (
                <IntroGerman
                    setCurrentForm={setCurrentForm}
                    language={language}
                />
            )}
            {currentForm === "intro" && language === "french" && (
                <IntroFrench
                    setCurrentForm={setCurrentForm}
                    language={language}
                />
            )}
            {currentForm === "intro" && language === "english" && (
                <IntroEnglish
                    setCurrentForm={setCurrentForm}
                    language={language}
                />
            )}
            {currentForm === "gender" && (
                <Gender
                    currentForm={currentForm}
                    setCurrentForm={setCurrentForm}
                    setGender={setGender}
                    language={language}
                />
            )}
            {currentForm === "male" && (
                <Male
                    setCurrentForm={setCurrentForm}
                    setScore={setScore}
                    setSubmitId={setSubmitId}
                    language={language}
                />
            )}
            {currentForm === "female" && (
                <Female
                    setCurrentForm={setCurrentForm}
                    setScore={setScore}
                    setSubmitId={setSubmitId}
                    language={language}
                />
            )}
            {currentForm === "divers" && (
                <Divers
                    setCurrentForm={setCurrentForm}
                    setScore={setScore}
                    setSubmitId={setSubmitId}
                    language={language}
                />
            )}
            {currentForm === "results" && (
                <Results
                    score={score}
                    setCurrentForm={setCurrentForm}
                    gender={gender}
                    submitId={submitId}
                    language={language}
                />
            )}
            {/*currentForm === "feedback" && (
                <Feedback setCurrentForm={setCurrentForm} />
            )*/}
        </main>
    );
};
export default Main;
