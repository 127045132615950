import React from "react";
import Beratungsstellen from "./Beratungsstellen";
import { useLocale } from "../../hooks/localeContext";

const TextFinalYellow: React.FC = () => {
    const { locale } = useLocale();
    return (
        <div className={"results__information"}>
            {locale === "de" && (
                <>
                    <h2>
                        Ihre Beziehung hat eine gute Basis, ist aber noch nicht
                        ganz perfekt!
                    </h2>
                    <h3>Nicht ganz egalitäre / ungleiche Beziehungsführung</h3>
                    <p>
                        Ihr Test zeigt keine Anzeichen einer seelisch oder
                        körperlich gewalttätigen Beziehung auf. Allerdings
                        liegen Warnsignale vor, die für eine eher ungleiche
                        Beziehungsführung sprechen. Diese Anzeichen können
                        aufzeigen, dass ein Ungleichgewicht in den Bereichen des
                        respektvollen und/oder ehrlichen Umgangs miteinander
                        bestehen. Ebenso kann das Ergebnis darauf hinweisen,
                        dass zu wenig und/oder eine nicht immer so gute
                        Kommunikation zwischen Ihnen vorhanden ist.
                    </p>
                    <p>
                        Bitte beachten Sie, dass dieses Testergebnis keinen
                        Aufschluss darüber gibt, wie zufrieden Sie in Ihrer
                        Beziehung sind, da dies auch weitere Gründe haben kann.
                    </p>
                    <p>
                        Wenn Sie Unterstützung benötigen, können Sie sich
                        jederzeit gerne an folgende Beratungsstelle wenden:
                    </p>
                </>
            )}
            {locale === "fr" && (
                <>
                    <h2>
                        Votre relation repose sur une base solide, mais n’est
                        pas encore tout à fait « parfaite » !
                    </h2>
                    <h3>Relation qui n’est pas entièrement égalitaire.</h3>
                    <p>
                        Votre test ne montre pas de signes de violence psychique
                        ou physique. Toutefois, des signaux d’alerte existent et
                        tendent vers une relation non-égalitaire. Ces signes
                        peuvent indiquer un déséquilibre dans les domaines du
                        respect et/ou de la sincérité réciproques. <br />
                        De même, le résultat peut indiquer qu’il existe trop peu
                        de communication ou une communication non-adéquate au
                        sein de votre couple.
                    </p>
                    <p>
                        Veuillez noter que ce résultat de test n’évalue pas
                        votre degré de satisfaction dans votre relation parce
                        que d’autres éléments peuvent entrer en ligne de compte.
                    </p>
                    <p>
                        Si vous avez besoin d’aide ou de conseils, n’hésitez pas
                        à vous adresser à un des centres suivants :
                    </p>
                </>
            )}
            {locale === "en" && (
                <>
                    <h2>
                        Your relationship has a good basis, but it is not
                        entirely perfect!
                    </h2>
                    <h3>Not an entirely egalitarian/unequal relationship</h3>
                    <p>
                        Your test does not show any signs of emotional abuse or
                        physical violence in your relationship. However, there
                        are warning signs of an unequal relationship. These
                        signs may point to inequality with regards to respectful
                        and/or honest interaction with one another. The results
                        may also indicate that there is too little communication
                        between you, or that the communication is not always so
                        successful.
                    </p>
                    <p>
                        Please note that this test result is not intended to
                        indicate how satisfied you are in your relationship, as
                        this may be based on other factors.
                    </p>
                    <p>
                        If you need help, you can contact the following
                        counseling center at any time:
                    </p>
                </>
            )}
            <Beratungsstellen />
        </div>
    );
};

export default TextFinalYellow;
