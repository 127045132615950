import React from "react";
import { useLocale } from "../../hooks/localeContext";
import Beratungsstellen from "./Beratungsstellen";

const TextFinalOrange: React.FC = () => {
    const { locale } = useLocale();
    return (
        <div className={"results__information"}>
            {locale === "de" && (
                <>
                    <h2>Achtung! Ihr Testergebnis zeigt Warnsignale auf!</h2>
                    <h3>Emotionale / psychische Gewalt</h3>
                    <p>
                        Ihr Test zeigt Warnsignale oder gar Anzeichen der
                        seelischen Gewalt innerhalb Ihrer Partnerschaft an.
                        Diese können auch auf eine Dynamik hinweisen, die in
                        körperliche Gewalt münden kann. Es ist wichtig, dass Sie
                        sich Unterstützung suchen.
                    </p>
                    <p>
                        Wenn Sie Unterstützung in Anspruch nehmen möchten,
                        können Sie sich jederzeit an folgende Beratungsstelle
                        wenden:
                    </p>
                </>
            )}
            {locale === "fr" && (
                <>
                    <h2>
                        Attention ! Le résultat de votre test montre des signes
                        d’alerte !
                    </h2>
                    <h3>Violence émotionnelle/psychique</h3>
                    <p>
                        Votre test montre des signes d’alerte voire des signes
                        de violence émotionnelle au sein de votre couple. Ces
                        signes peuvent mettre en évidence une dynamique qui
                        pourrait aboutir à de la violence physique. Il est
                        important que vous cherchiez de l’aide.
                    </p>
                    <p>
                        Si vous avez besoin d’aide ou de conseils, n’hésitez pas
                        à vous adresser à un des centres suivants :
                    </p>
                </>
            )}

            {locale === "en" && (
                <>
                    <h2>Watch out! Your test result shows warning signs!</h2>
                    <h3>Emotional/psychological abuse</h3>
                    <p>
                        Your test shows warning signs or even indications of
                        emotional abuse in your partnership. These may also
                        point to a dynamic that could result in physical
                        violence. It is important that you seek help.
                    </p>
                    <p>
                        If you would like help, you can contact the following
                        counseling center at any time:
                    </p>
                </>
            )}
            <Beratungsstellen />
        </div>
    );
};

export default TextFinalOrange;
