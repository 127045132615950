import React from "react";

const Beratungsstellen: React.FC = () => {
    return (
        <ul>
            <li>
                <strong>helpline violence domestique</strong>
                <br />
                T: <a href="tel:+35220601060">+352 2060 1060</a>
                <br />
                e-mail:{" "}
                <a href="mailto:info@helpline-violence.lu">
                    info@helpline-violence.lu
                </a>
                <br />
                <a
                    href="https://www.helpline-violence.lu"
                    target="_blank"
                    rel="noreferrer"
                >
                    www.helpline-violence.lu
                </a>
            </li>
            <li>
                <strong>
                    Centre Ozanam Centre de consultations pour jeunes mamans et
                    femmes en détresse
                </strong>
                <br />
                T: <a href="tel:+352406001310">+352 406001-310</a>
                <br />
                e-mail: <a href="mailto:ozanam@fmpo.lu">ozanam@fmpo.lu</a>
                <br />
                <a href="https://www.fmpo.lu" target="_blank" rel="noreferrer">
                    www.fmpo.lu
                </a>
            </li>
            <li>
                <strong>
                    Centre Ozanam Nord Centre de consultations pour jeunes
                    mamans et femmes en détresse
                </strong>
                <br />
                T: <a href="tel:+352406001300">+352 406001-300</a>
                <br />
                e-mail:{" "}
                <a href="mailto:ozanam.nord@fmpo.lu">ozanam.nord@fmpo.lu</a>
                <br />
                <a href="https://www.fmpo.lu" target="_blank" rel="noreferrer">
                    www.fmpo.lu
                </a>
            </li>
            <li>
                <strong>Espace Femmes - Centre de consultation</strong>
                <br />
                T: <a href="tel:+35251727288">+352 51 72 72-88</a>
                <br />
                e-mail:{" "}
                <a href="mailto:femmes@profamilia.lu">
                    femmes@profamilia.lu
                </a>{" "}
                <br />
                <a
                    href="http://www.profamilia.lu"
                    target="_blank"
                    rel="noreferrer"
                >
                    www.profamilia.lu
                </a>
            </li>
            <li>
                <strong>Espace Femmes - Centre d’accueil</strong>
                <br />
                T: <a href="tel:+35251727241">+352 51 72 72-41</a>
                <br />
                e-mail:{" "}
                <a href="mailto:femmes@profamilia.lu">femmes@profamilia.lu</a>
                <br />
                <a
                    href="http://www.profamilia.lu"
                    target="_blank"
                    rel="noreferrer"
                >
                    www.profamilia.lu
                </a>
            </li>
            <li>
                <strong>infoMann Centre de consultations pour hommes</strong>
                <br />
                T: <a href="tel:+352274965">+352 27 49 65</a>
                <br />
                e-mail:{" "}
                <a href="mailto:info@helpline-violence.lu">info@infomann.lu</a>
                <br />
                <a
                    href="http://www.infomann.lu"
                    target="_blank"
                    rel="noreferrer"
                >
                    www.infomann.lu
                </a>
            </li>
            <li>
                <strong>Log-in Centre de consultation</strong>
                <br />
                T: <a href="tel:+352545757">+352 54 57 57</a>
                <br />
                e-mail: <a href="mailto:login@foyersud.lu">
                    login@foyersud.lu
                </a>{" "}
                <br />{" "}
                <a href="https://www.cnfl.lu" target="_blank" rel="noreferrer">
                    www.cnfl.lu
                </a>
            </li>
            <li>
                <strong>VISAVI Consultations pour femmes</strong>
                <br />
                T: <a href="tel:+3524908771">+352 49 08 77-1</a>
                <br />
                e-mail: <a href="mailto:visavi@fed.lu">visavi@fed.lu</a>
                <br />
                <a href="https://www.fed.lu" target="_blank" rel="noreferrer">
                    www.fed.lu
                </a>
            </li>
        </ul>
    );
};

export default Beratungsstellen;
