import React from "react";

const Logo: React.FC = () => {
    return (
        <a href="." className="logo-heading__link">
            <h1 className="logo-heading">#relation2test</h1>
        </a>
    );
};
export default Logo;
