import React from "react";
import Link from "next/link";

const Footer: React.FC = () => {
    return (
        <footer id="footer">
            <div className="content flex justify-start gap-2">
                <span>&copy; 2021 relation2test</span>
                <div className="flex flex--gap">
                    <Link href="/datenschutz">
                        <a>Datenschutz</a>
                    </Link>
                    <Link href="/impressum">
                        <a>Impressum</a>
                    </Link>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
