import React from "react";
import Navigation from "../components/Page/Navigation";
import Main from "../components/Page/Main";
import Footer from "../components/Page/Footer";
import HtmlHead from "../components/Page/HtmlHead";

const Home: React.FC = () => {
    return (
        <div className="global">
            <HtmlHead></HtmlHead>

            <Navigation />
            <Main />
            <Footer />
        </div>
    );
};

export default Home;
