import React, { useEffect } from "react";
import { createWidget } from "@typeform/embed";
import { FormProps } from "../../types/FormProps";

const Divers: React.FC<FormProps> = ({
    setCurrentForm,
    setScore,
    setSubmitId,
    language,
}) => {
    useEffect(() => {
        let formId = "IuBYG6ua"; // german
        if (language === "french") formId = "MdLuRn5Z";
        else if (language === "english") formId = "UWvSdqpl";

        createWidget(formId, {
            container: document.getElementById("typeform-divers"),
            hideFooter: false,
            hideHeaders: true,
            opacity: 0,
            onSubmit: (event) => {
                let id = event.responseId;
                //console.log("response event: ", event.responseId)
                setSubmitId(id);
                const call = async () => {
                    await fetch(`/api/eval/${formId}/${id}`, {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    })
                        .then((response) => response.json())
                        .then((res) => {
                            //console.log(res);
                            setScore({
                                sumBlue: res[1],
                                sumGreen: res[2],
                                sumRed: res[3],
                            });
                            setCurrentForm("results");
                        })
                        .catch((error) => console.log(error));
                };

                setTimeout(() => call().then(), 1500);

                //console.log("Submit Divers");
            },
        });
    }, []);

    return <div id="typeform-divers" className="typeform-container"></div>;
};
export default Divers;
