import React from "react";
import { useLocale } from "../../hooks/localeContext";
import Beratungsstellen from "./Beratungsstellen";

const TextFinalRed: React.FC = () => {
    const { locale } = useLocale();
    return (
        <div className={"results__information"}>
            {locale === "de" && (
                <>
                    <h2>
                        Vorsicht! Ihre Beziehung zeigt deutliche Warnsignale für
                        körperliche Gewalt auf!{" "}
                    </h2>
                    <h3>Körperliche Gewalt </h3>
                    <p>
                        Ihr Test zeigt ernste Warnsignale der körperlichen
                        Gewalt oder bereits vorhandene häusliche Gewalt
                        innerhalb Ihrer Partnerschaft an. Sie müssen damit nicht
                        alleine bleiben und können sich jederzeit Unterstützung
                        holen.
                    </p>
                    <p>
                        Wenn Sie Unterstützung zur Lösung Ihrer individuellen
                        Situation in Anspruch nehmen möchten, können Sie sich
                        jederzeit an folgende Beratungsstellen wenden:
                    </p>
                    <p>
                        In einer drohenden oder akuten Gewaltsituation
                        kontaktieren Sie bitte direkt die Polizei!Tél.:
                        <a href="tel:113">113</a>
                    </p>
                </>
            )}
            {locale === "fr" && (
                <>
                    <h2>
                        Attention ! Votre relation montre des signes sérieux de
                        violence physique !
                    </h2>
                    <h3>La violence physique</h3>
                    <p>
                        Votre test montre de sérieux signes de violence physique
                        ou l’existence de violence domestique au sein de votre
                        relation. Ne restez pas seul avec ces problèmes. Vous
                        pouvez à tout moment demander aide et conseil aux
                        centres suivants :
                    </p>
                    <p>
                        En cas de situation de violence imminente ou aiguë,
                        veuillez contacter directement la police ! Tél.:
                        <a href="tel:113">113</a>
                    </p>
                </>
            )}
            {locale === "en" && (
                <>
                    <h2>
                        Careful! Your relationship shows clear warning signs of
                        physical violence!
                    </h2>
                    <h3>Physical violence</h3>
                    <p>
                        Your test shows serious warning signs of physical
                        violence or existing domestic abuse in your partnership.
                        You do not need to handle this situation alone and can
                        get help at any time. If you would like help solving
                        your individual situation, you can contact the following
                        counseling centers at any time:
                    </p>
                    <p>
                        In an imminent or acute violent situation, please
                        contact the police directly! T:{" "}
                        <a href="tel:113">113</a>
                    </p>
                </>
            )}
            <Beratungsstellen />
        </div>
    );
};

export default TextFinalRed;
