import { ResponseItemData, Demography } from "../types/ResponseDataProps";
type Eval = "" | "green" | "yellow" | "orange" | "red" | "error";
interface Props {
    item: ResponseItemData;
    score: any;
    setError: (error: boolean) => void;
    demography: Demography | null;
}
type ReturnType = ResponseItemData | Eval;

const calcEval = <Props>(
    item,
    score,
    demography = null,
    setError = (value: boolean) => {},
): ResponseItemData => {
    const { sumBlue, sumGreen, sumRed } = score;
    //console.log("calcEval - Score: ", score);
    let evalBlue: Eval = "";
    let evalGreen: Eval = "";
    let evalRed: Eval = "";
    let finalEval: Eval = "";

    /** SumBlue Eval - egalitäre Beziehung **/
    if (sumBlue <= 3) evalBlue = "green";
    else if (sumBlue > 3 && sumBlue <= 5) evalBlue = "yellow";
    else if (sumBlue > 5) evalBlue = "red";
    else setError(true);

    /** SumGreen Eval - emotionale Warnsignale **/
    if (sumGreen <= 3.4) evalGreen = "green";
    else if (sumGreen > 3.4 && sumGreen <= 6.9) evalGreen = "yellow";
    else if (sumGreen > 6.9 && sumGreen <= 10.4) evalGreen = "orange";
    else if (sumGreen > 10.4) evalGreen = "red";
    else setError(true);

    /** SumRed Eval - Körperliche Warnsignale **/
    if (sumRed <= 1.7) evalRed = "green";
    else if (sumRed > 1.7 && sumRed <= 3.3) evalRed = "yellow";
    else if (sumRed > 3.3 && sumRed <= 5) evalRed = "orange";
    else if (sumRed > 5) evalRed = "red";
    else setError(true);

    if (
        (evalBlue === "green" || evalBlue === "yellow") &&
        evalGreen === "green" &&
        evalRed === "green"
    )
        finalEval = "green";
    else if (evalBlue === "red" && evalGreen === "green" && evalRed === "green")
        finalEval = "yellow";
    else if (
        (evalBlue === "green" || evalBlue === "yellow" || evalBlue === "red") &&
        (evalGreen === "yellow" ||
            evalGreen === "orange" ||
            evalGreen === "red") &&
        evalRed === "green"
    )
        finalEval = "orange";
    else if (sumRed > 1.7) finalEval = "red";
    else finalEval = "orange";

    let finalEvalString = "";

    if (finalEval === "green") finalEvalString = "Egalitäre Beziehungsführung";
    else if (finalEval === "yellow")
        finalEvalString = "Ungleiche Beziehungsführung";
    else if (finalEval === "orange")
        finalEvalString = "Emotionale/psychische Gewalt";
    else if (finalEval === "red") finalEvalString = "Körperliche Gewalt";

    let date = new Date(item?.submitted_at).toLocaleString("de-DE");

    return {
        response_id: item?.response_id,
        submitted_at: item?.submitted_at,
        sumBlue: sumBlue,
        sumGreen: sumGreen,
        sumRed: sumRed,
        evalBlue: evalBlue,
        evalGreen: evalGreen,
        evalRed: evalRed,
        finalEval: finalEval,
        finalEvalString: finalEvalString,
        demography: demography,
        date: date,
    };
};

export default calcEval;
