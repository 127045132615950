import React, { useContext } from "react";
import Logo from "./Logo";
import Link from "next/link";
import useTranslation from "../../hooks/useTranslation";

const Navigation: React.FC = () => {
    const { locale, t } = useTranslation();

    const mobileCheckboxClick = () => {
        document.getElementById("mainNav").classList.toggle("showBackground");
    };

    return (
        <header>
            <nav id="mainNav">
                <div className="content content--no-padding flex flex-space-between flex-direction--row flex-center--vertical">
                    <Logo />
                    <div className="nav__wrapper">
                        <input
                            type="checkbox"
                            className="mobileCheckbox"
                            onClick={mobileCheckboxClick}
                        />
                        <span className="mobileToggle"> </span>
                        <span className="mobileToggle"> </span>
                        <span className="mobileToggle"> </span>
                        <div className="nav__inner">
                            <ul>
                                <li>
                                    <Link
                                        href={
                                            "/info" +
                                            (locale != "fr" ? "-" + locale : "")
                                        }
                                    >
                                        <a className="navLink">Info</a>
                                    </Link>
                                </li>
                                {/*<li>
                                    {" "}
                                    <Link href="/hilfe">
                                        <a className="navLink">{t("help")}</a>
                                    </Link>
                                </li>*/}
                                <li>
                                    <Link
                                        href={
                                            "/apropos" +
                                            (locale != "fr" ? "-" + locale : "")
                                        }
                                    >
                                        <a className="navLink">{t("about")}</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/kontakt">
                                        <a className="navLink">
                                            {t("contact")}
                                        </a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};
export default Navigation;
