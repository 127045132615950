import React, { useState, useEffect } from "react";
import { createWidget } from "@typeform/embed";
import { FormProps } from "../../types/FormProps";
import { useLocale } from "../../hooks/localeContext";

const Gender: React.FC<FormProps> = ({
    currentForm,
    setCurrentForm,
    setGender,
    language,
}) => {
    const [start, setStart] = useState(true);
    const { locale } = useLocale();
    //return <div id="typeform-gender" className="typeform-container"></div>;

    return (
        <div
            id="typeform-gender"
            className={
                "typeform-container typeform-startscreen genderscreen" +
                (!start ? " bigTop" : "")
            }
        >
            {start ? (
                <div className="typeform-startscreen__inner ">
                    {locale === "fr" && (
                        <>
                            <h3>test de relation</h3>
                            <p className="bigger">
                                Ce questionnaire comprend un total de 16
                                questions, sans limite de temps. Veuillez
                                répondre à chaque question sans en oublier. Il
                                n'y a pas de "bonnes" ou de "mauvaises" réponses
                                aux questions. Lorsque vous remplissez le
                                questionnaire, répondez de manière à refléter le
                                mieux possible vos expériences personnelles au
                                sein de votre couple. Ne laissez pas votre
                                humeur du moment influencer votre réponse.
                                <br />
                                Vos données sont traitées de manière
                                confidentielle. Une anonymisation des données
                                est garantie afin de rendre votre identification
                                impossible.
                            </p>
                        </>
                    )}
                    {locale === "de" && (
                        <>
                            <h3>Beziehungstest</h3>
                            <p className="bigger">
                                Dieser Fragebogen umfasst insgesamt 16 Fragen
                                ohne zeitliche Begrenzung. Bitte bearbeiten Sie
                                jede einzelne Aussage und lassen Sie keine aus.
                                Bei den Fragen gibt es keine "richtigen" oder
                                "falschen" Antworten. Antworten Sie bei der
                                Bearbeitung des Fragebogens so, wie es Ihren
                                persönlichen Erfahrungen innerhalb Ihrer
                                Beziehung am ehesten entspricht. Lassen Sie sich
                                nicht durch eine aktuelle Stimmung bei der
                                Beantwortung beeinflussen.
                                <br />
                                Ihre Angaben werden selbstverständlich
                                vertraulich behandelt. Eine Anonymisierung der
                                Angaben ist gewährleistet, so dass ein
                                Rückschluss auf Ihre Person nicht möglich ist.
                            </p>
                        </>
                    )}
                    {locale === "en" && (
                        <>
                            <h3>Relationtest</h3>
                            <p className="bigger">
                                This questionnaire includes a total of 16
                                questions with no time limit. Please edit each
                                statement and do not omit any. There are no
                                "right" or "wrong" answers. When completing the
                                questionnaire, please answer in the way that
                                most closely matches your personal experiences
                                within your relationship. Do not be influenced
                                by a current mood when answering. <br />
                                Your information will of course be treated
                                confidentially. Anonymization of the information
                                is guaranteed, so that it is not possible to
                                draw conclusions about your person.
                            </p>
                        </>
                    )}
                    <button
                        className="js-start button button-link primary primary--hover font--big color--secondary shadow--hover"
                        onClick={() => setStart(false)}
                    >
                        {language === "german" && <span>Starten</span>}
                        {language === "french" && <span>Commencer</span>}
                        {language === "english" && <span>Start</span>}
                    </button>
                </div>
            ) : (
                <div className="typeform-startscreen__inner flex-justify--start">
                    {language === "german" && (
                        <h3>Mein*e Partner*in ist ...*</h3>
                    )}
                    {language === "french" && <h3>Mon partenaire est ...*</h3>}
                    {language === "english" && <h3>My partner is ...*</h3>}
                    <button
                        className="button button-link typeform-style shadow--hover"
                        onClick={() => {
                            setGender("female");
                            setCurrentForm("female");
                        }}
                    >
                        {language === "german" && <span>Weiblich</span>}
                        {language === "french" && <span>féminin</span>}
                        {language === "english" && <span>female</span>}
                    </button>
                    <button
                        className="button button-link typeform-style shadow--hover"
                        onClick={() => {
                            setGender("male");
                            setCurrentForm("male");
                        }}
                    >
                        {language === "german" && <span>Männlich</span>}
                        {language === "french" && <span>masculin</span>}
                        {language === "english" && <span>male</span>}
                    </button>
                    <button
                        className="button button-link typeform-style shadow--hover"
                        onClick={() => {
                            setGender("divers");
                            setCurrentForm("divers");
                        }}
                    >
                        {language === "german" && <span>Divers</span>}
                        {language === "french" && <span>divers</span>}
                        {language === "english" && <span>divers</span>}
                    </button>
                </div>
            )}
        </div>
    );
};
export default Gender;
